import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subject, switchMap} from 'rxjs';
import {
    AppointmentDto,
    AppointmentDtoPagedResultDto,
    AppointmentServiceProxy,
    AppointmentTemplateDto,
    AppointmentTemplateServiceProxy,
    AppointmentTypeDto,
    AppointmentTypeServiceProxy,
    CreateOrEditAppointmentDto,
    DmpAppointmentProcessedDto,
    DmpAppointmentServiceProxy,
    OfficeHourDto,
    OfficeHourServiceProxy,
    PractitionerDto,
    PractitionerServiceProxy,
    PractitionerStateForAppointmentDto,
    UpdateAppointmentDetailsDto,
    UpdateAppointmentPractitionerStatesDto,
    UserDto,
    UserServiceProxy
} from '@shared/service-proxies/service-proxies';
import {first, map, of, tap} from '@node_modules/rxjs';

import * as moment from 'moment';
import {
    AppointmentNotification,
    AppointmentPractitionerStatesNotificationData,
    DmpStatusNotificationData,
    EntityNotification
} from '@shared/notification-models';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    appointmentTypeToName = {};

    practitionerToName = {};

    appointmentTypes: AppointmentTypeDto[] = [];

    appointmentTemplates: AppointmentTemplateDto[] = [];

    practitioners: PractitionerDto[] = [];

    officeHours: OfficeHourDto[] = [];

    appointments: AppointmentDto[] = [];

    dmpAppointments: DmpAppointmentProcessedDto[] = [];

    onUpdateAppointment$: Observable<{ appointment: AppointmentDto, internalChange: boolean }>;
    onUpdateAppointmentPractitionerStates$: Observable<{ appointment: AppointmentDto }>;
    onDeleteAppointment$: Observable<number>;
    onUpdateDmpStatus$: Observable<DmpAppointmentProcessedDto[]>;
    officeHours$: Observable<OfficeHourDto[]>;
    practitioners$: Observable<PractitionerDto[]>;
    appointmentTypes$: Observable<AppointmentTypeDto[]>;
    appointmentTemplates$: Observable<AppointmentTemplateDto[]>;

    private _dateRange: { start: moment.Moment, end: moment.Moment } = {
        start: undefined,
        end: undefined
    };

    private _users: UserDto[];

    private _onUpdateAppointmentSubject: Subject<{ appointment: AppointmentDto, internalChange: boolean }>;
    private _onUpdateAppointmentPractitionerStatesSubject: Subject<{ appointment: AppointmentDto }>;
    private _onDeleteAppointmentSubject: Subject<number>;
    private _onUpdateDmpStatusSubject: Subject<DmpAppointmentProcessedDto[]>;
    private _onOfficeHours: BehaviorSubject<OfficeHourDto[]>;
    private _onPractitioners: BehaviorSubject<PractitionerDto[]>;
    private _onAppointmentTypes: BehaviorSubject<AppointmentTypeDto[]>;
    private _onAppointmentTemplates: BehaviorSubject<AppointmentTemplateDto[]>;

    private _ownChangedAppointments = {};

    private _entityUpdateMap = {};

    constructor(private _appointmentService: AppointmentServiceProxy,
                private _appointmentTypeService: AppointmentTypeServiceProxy,
                private _appointmentTemplateService: AppointmentTemplateServiceProxy,
                private _practitionerService: PractitionerServiceProxy,
                private _officeHourService: OfficeHourServiceProxy,
                private _dmpService: DmpAppointmentServiceProxy,
                private _userService: UserServiceProxy) {
        this._onUpdateAppointmentSubject = new Subject<{ appointment: AppointmentDto, internalChange: boolean }>();
        this.onUpdateAppointment$ = this._onUpdateAppointmentSubject.asObservable();
        this._onUpdateAppointmentPractitionerStatesSubject = new Subject<{ appointment: AppointmentDto }>();
        this.onUpdateAppointmentPractitionerStates$ = this._onUpdateAppointmentPractitionerStatesSubject.asObservable();
        this._onDeleteAppointmentSubject = new Subject<number>();
        this.onDeleteAppointment$ = this._onDeleteAppointmentSubject.asObservable();
        this._onUpdateDmpStatusSubject = new Subject<DmpAppointmentProcessedDto[]>();
        this.onUpdateDmpStatus$ = this._onUpdateDmpStatusSubject.asObservable();
        this._onOfficeHours = new BehaviorSubject<OfficeHourDto[]>([]);
        this.officeHours$ = this._onOfficeHours.asObservable();
        this._onPractitioners = new BehaviorSubject<PractitionerDto[]>([]);
        this.practitioners$ = this._onPractitioners.asObservable();
        this._onAppointmentTypes = new BehaviorSubject<AppointmentTypeDto[]>([]);
        this.appointmentTypes$ = this._onAppointmentTypes.asObservable();
        this._onAppointmentTemplates = new BehaviorSubject<AppointmentTemplateDto[]>([]);
        this.appointmentTemplates$ = this._onAppointmentTemplates.asObservable();

        this._entityUpdateMap = {
            'PractitionerDto': {
                get: (id) => this._practitionerService.get(id),
                list: () => this.practitioners,
                publish: () => this._onPractitioners.next(this.practitioners),
            },
            'OfficeHourDto': {
                get: (id) => this._officeHourService.get(id),
                list: () => this.officeHours,
                publish: () => this._onOfficeHours.next(this.officeHours),
            },
            'AppointmentTypeDto': {
                get: (id) => this._appointmentTypeService.get(id),
                list: () => this.appointmentTypes,
                publish: () => this._onAppointmentTypes.next(this.appointmentTypes),
            },
            'AppointmentTemplateDto': {
                get: (id) => this._appointmentTemplateService.get(id),
                list: () => this.appointmentTemplates,
                publish: () => this._onAppointmentTemplates.next(this.appointmentTemplates),
            },
            'UserDto': {
                get: (id) => this._userService.get(id),
                list: () => this._users,
                publish: () => {
                },
            }
        };
    }

    init(): Observable<boolean> {
        if (this.appointmentTypes.length > 0 && this.appointmentTemplates.length > 0
            && this.officeHours.length > 0 && this.practitioners.length > 0 && this.officeHours.length > 0) {
            return of(true);
        }

        return forkJoin({
            types: this._appointmentTypeService.getAll(undefined, undefined, 'name', undefined, 1000).pipe(first()),
            templates: this._appointmentTemplateService.getAll(undefined, undefined, undefined, 'name', undefined, 1000).pipe(first()),
            officeHours: this._officeHourService
                .getAll(undefined, undefined, 'officeHourType asc, name asc', undefined, 1000).pipe(first()),
            practitioners: this._practitionerService.getAll(undefined, undefined, undefined, 'name', undefined, 1000).pipe(first()),
            users: this._userService.getAll(undefined, true, undefined, 100).pipe(first())
        }).pipe(
            first(),
            map(({types, templates, officeHours, practitioners, users}) => {
                this.appointmentTypes = types.items.filter(type => !!type.id);
                this.appointmentTypes
                    .forEach(type => this.appointmentTypeToName[type.id] = type.name);

                this.appointmentTemplates = templates.items.filter(t => t.isActive);

                this.officeHours = officeHours.items.filter(oh => oh.isActive);

                this.practitioners = practitioners.items.filter(item => !!item.id);
                this.practitioners
                    .forEach(practitioner => this.practitionerToName[practitioner.id] = practitioner.name);

                this._users = users.items;

                this._onPractitioners.next(this.practitioners);
                this._onOfficeHours.next(this.officeHours);
                this._onAppointmentTypes.next(this.appointmentTypes);
                this._onAppointmentTemplates.next(this.appointmentTemplates);

                return true;
            }));
    }

    getAppointments(startTime?: moment.Moment, endTime?: moment.Moment): Observable<AppointmentDto[]> {
        let appointmentsAreUpToDate: boolean;
        if (!startTime || !endTime) {
            appointmentsAreUpToDate = true;
        } else {
            if (this._dateRange.start && this._dateRange.end &&
                startTime >= this._dateRange.start && startTime <= this._dateRange.end &&
                endTime >= this._dateRange.start && endTime <= this._dateRange.end) {
                appointmentsAreUpToDate = true;
            } else {
                appointmentsAreUpToDate = false;
                startTime = startTime.subtract(7, 'days');
                endTime = endTime.add(7, 'days');
            }
        }

        let observable;
        if (appointmentsAreUpToDate) {
            observable = of({items: this.appointments});
        } else {
            this._dateRange.start = startTime;
            this._dateRange.end = endTime;
            observable = this._appointmentService
                .getAll(undefined, undefined, undefined, startTime, endTime, false, undefined, false, undefined, 0, 20000);
        }

        return observable
            .pipe(
                first(),
                map((result: AppointmentDtoPagedResultDto) => {
                    this.appointments = result.items;
                    return this.appointments;
                })
            );
    }

    triggerGetDmpStatus(): void {
        if (!this._dateRange.start || !this._dateRange.end) {
            return;
        }

        this._dmpService
            .getAllProcessed(this._dateRange.start, this._dateRange.end)
            .subscribe(result => {
                this.dmpAppointments = result;
                this._onUpdateDmpStatusSubject.next(result);
            });
    }

    getAppointment(id: number): Observable<AppointmentDto> {
        const appointment = this.appointments.find(a => a.id === id);
        if (appointment) {
            return of(appointment);
        }

        return this._appointmentService
            .get(id)
            .pipe(
                tap(newAppointment => {
                    this.appointments.push(newAppointment);
                })
            );
    }

    getAppointmentsOfAppointment(id: number): Observable<AppointmentDto[]> {
        const appointments = this.appointments
            .filter(a => a.hauptterminId === id || a.id === id);
        if (appointments.length === 1 && !appointments[0].istKettentermin && !appointments[0].hauptterminId) {
            // Wenn es kein Ketterntemrin ist, dann gibt es nur ein Appointment.
            return of(appointments);
        }

        if (appointments.length > 2) {
            const mainAppointment = appointments.find(x => x.istKettentermin);
            if (mainAppointment) {
                const startAppointment = appointments
                    .find(x => !x.istKettentermin && x.startTime && moment(x.startTime).isSame(mainAppointment.startTime));
                const endAppointment = appointments
                    .find(x => !x.istKettentermin && x.endTime && moment(x.endTime).isSame(mainAppointment.endTime));

                if (startAppointment && endAppointment) {
                    return of(appointments);
                }
            } else {
                return of(appointments);
            }
        }

        return this._appointmentService
            .getAppointmentsOfAppointment(id)
            .pipe(
                tap(newAppointments => {
                    newAppointments.forEach(a => {
                        if (!this.appointments.find(x => x.id === a.id)) {
                            this.appointments.push(a);
                        }
                    });
                })
            );
    }

    getAppointmentForEdit(id: number): Observable<CreateOrEditAppointmentDto> {
        return this
            .getAppointment(id)
            .pipe(
                switchMap(appointment => {
                    const mainId = appointment.hauptterminId
                        ? appointment.hauptterminId
                        : appointment.id;
                    return this._appointmentService.getForEdit(mainId);
                })
            );
    }

    createAppointment(appointment: CreateOrEditAppointmentDto): Observable<AppointmentDto[]> {
        return this
            ._appointmentService
            .createAppointment(appointment)
            .pipe(
                map(appointments => {
                    return appointments
                        .map(app => this._updateAppointment(app, true) ?? app);
                })
            );
    }

    editAppointment(appointment: CreateOrEditAppointmentDto): Observable<AppointmentDto[]> {
        return this
            ._appointmentService
            .edit(appointment)
            .pipe(
                map(appointments => {
                    return appointments
                        .map(app => this._updateAppointment(app, true) ?? app);
                })
            );
    }

    updateAppointment(appointment: AppointmentDto): Observable<AppointmentDto> {
        return this
            ._appointmentService
            .updateAppointment(appointment)
            .pipe(
                map(appointments => {
                    appointments.forEach(app => this._updateAppointment(app, true));
                    const existingAppointment = this.appointments.find(app => app.id === appointment.id);
                    return existingAppointment ?? appointments.find(app => app.id === appointment.id);
                })
            );
    }

    updateAppointmentDetails(details: UpdateAppointmentDetailsDto): Observable<void> {
        return this
            ._appointmentService
            .updateAppointmentDetails(details)
            .pipe(
                map(appointments => {
                    appointments.forEach(app => this._updateAppointment(app, true));
                })
            );
    }

    updatePractitionerStates(states: PractitionerStateForAppointmentDto[]): Observable<void> {
        return this
            ._appointmentService
            .updateAppointmentPractitionerStates(new UpdateAppointmentPractitionerStatesDto({
                practitionerStates: states
            }))
            .pipe(map(() => this._updateAppointmentPractitionerStates(states)));
    }

    deleteAppointment(id: number): Observable<void> {
        return this
            ._appointmentService
            .delete(id)
            .pipe(
                map(() => {
                    this._removeAppointment(id);
                })
            );
    }

    entityNotificationReceived(notification: EntityNotification) {
        const entityUpdate = this._entityUpdateMap[notification.data.properties.TypeName];
        if (!entityUpdate) {
            console.error('EntityUpdate Logik nicht gefunden.');
            return;
        }

        const entityId = notification.data.properties.Id;
        if (notification.data.properties.Updated || notification.data.properties.Inserted) {
            entityUpdate
                .get(entityId)
                .subscribe(entity => {
                    const existing = entityUpdate.list().find(e => e.id === entityId);
                    if (existing) {
                        existing.init(entity);
                    } else {
                        entityUpdate.list().push(entity);
                    }
                    entityUpdate.publish();
                });
        } else if (notification.data.properties.Deleted) {
            const index = entityUpdate.list().findIndex(e => e.id === entityId);
            if (index >= 0) {
                entityUpdate.list().splice(index, 1);
                entityUpdate.publish();
            }
        }
    }

    dmpStatusNotificationReceived(notification: DmpStatusNotificationData) {
        const properties = notification.data.properties;
        if (!properties.Id || !properties.AppointmentId) {
            return;
        }

        let dmpStatus = this
            .dmpAppointments
            .find(dmp => dmp.id === properties.Id);
        if (dmpStatus) {
            if (dmpStatus.processed === properties.Processed) {
                return;
            }
            dmpStatus.processed = properties.Processed;
        } else {
            dmpStatus = new DmpAppointmentProcessedDto({
                id: properties.Id,
                appointmentId: properties.AppointmentId,
                processed: properties.Processed
            });
            this.dmpAppointments.push(dmpStatus);
        }
        this._onUpdateDmpStatusSubject.next([dmpStatus]);
    }

    notificationReceived(notification: AppointmentNotification) {
        if (notification.data.properties.Deleted) {
            this._removeAppointment(notification.data.properties.Id);
            return;
        }

        const momentStart = moment(notification.data.properties.StartTime);
        if (this._dateRange.start && this._dateRange.end &&
            momentStart >= this._dateRange.start && momentStart <= this._dateRange.end) {
            this._appointmentService.get(notification.data.properties.Id)
                .pipe(first())
                .subscribe(appointment => {
                    this._updateAppointment(appointment, false);
                });
        }
    }

    appointmentPractitionerStatesNotificationReceived(notification: AppointmentPractitionerStatesNotificationData): void {
        const states = notification.data
            .properties
            .States
            .map(s => new PractitionerStateForAppointmentDto({
                state: <any>s.state,
                appointmentId: s.appointmentId,
                id: s.practitionerId
            }));
        this._updateAppointmentPractitionerStates(states);
    }

    getUser(id: number): UserDto {
        return this._users?.find(u => u.id === id);
    }

    private _updateAppointment(appointment: AppointmentDto, internalChange: boolean): AppointmentDto {
        let existingAppointment = this.appointments.find(a => a.id === appointment.id);
        if (existingAppointment) {
            const patientDateOfBirth = appointment.patientDateOfBirth;
            existingAppointment.init(appointment.toJSON());
            existingAppointment.patientDateOfBirth = patientDateOfBirth;
        } else {
            this.appointments.push(appointment);
            existingAppointment = appointment;
        }

        if (internalChange) {
            this._ownChangedAppointments[appointment.id] = moment();
        } else {
            const changeTime = this._ownChangedAppointments[appointment.id];
            if (changeTime) {
                const diff = moment().diff(changeTime, 'seconds');
                if (diff < 10) {
                    return;
                } else {
                    delete (this._ownChangedAppointments[appointment.id]);
                }
            }
        }

        this._onUpdateAppointmentSubject.next({appointment: existingAppointment, internalChange: internalChange});
        return existingAppointment;
    }

    private _removeAppointment(id: number): void {
        const index = this.appointments.findIndex(a => a.id === id);
        if (index >= 0) {
            const existing = this.appointments[index];
            this.appointments.splice(index, 1);

            if (existing.istKettentermin) {
                const subAppointmentIds = this.appointments
                    .filter(a => a.hauptterminId === id)
                    .map(a => a.id);
                for (let i = 0; i < subAppointmentIds.length; i++) {
                    const appIndex = this.appointments
                        .findIndex(a => a.id === subAppointmentIds[i]);
                    this.appointments.splice(appIndex, 1);
                    this._onDeleteAppointmentSubject.next(subAppointmentIds[i]);
                }
            }

            this._onDeleteAppointmentSubject.next(id);
        }
    }

    private _updateAppointmentPractitionerStates(states: PractitionerStateForAppointmentDto[]) {
        let hauptterminId = undefined;
        states.forEach(state => {
            const app = this.appointments.find(a => a.id === state.appointmentId);
            if (app) {
                const practitionerState = app.practitionerStates.find(s => s.id === state.id);
                if (practitionerState) {
                    practitionerState.state = state.state;
                    this._onUpdateAppointmentPractitionerStatesSubject.next({appointment: app});
                }

                if (app.hauptterminId) {
                    hauptterminId = app.hauptterminId;
                }
            }
        });

        if (hauptterminId) {
            const app = this.appointments.find(a => a.id === hauptterminId);
            if (app) {
                const statesForMainAppointment = [...states];
                app.practitionerStates.forEach(practitionerState => {
                    const state = statesForMainAppointment.find(s => s.id === practitionerState.id);
                    if (state) {
                        statesForMainAppointment.splice(states.indexOf(state), 1);
                        practitionerState.state = state.state;
                    }
                });
                this._onUpdateAppointmentPractitionerStatesSubject.next({appointment: app});
            }
        }
    }
}
