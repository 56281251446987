export class AppSettingNames {
    public static AppointmentTextConfirm = 'App.AppointmentText.Confirm';
    public static AppointmentTextCancel = 'App.AppointmentText.Cancel';
    public static AppointmentTextChange = 'App.AppointmentText.Change';
    public static AppointmentTextReminder = 'App.AppointmentText.Reminder';
    public static AppointmentTextConfirmOnlineBooking = 'App.AppointmentText.ConfirmOnlineBooking';
    public static SidebarIsExpanded = 'App.Sidebar.IsExpanded';
    public static RememberMeEnabled = 'App.RememberMeEnabled';
    public static RecallTextReminder = 'App.RecallText.Reminder';
}
