import {Pipe, PipeTransform} from '@angular/core';
import {StorageService} from '@shared/storage.service';

@Pipe({
    name: 'username'
})
export class UsernamePipe implements PipeTransform {

    constructor(private _storageService: StorageService) {
    }

    transform(userId: number, ...args: unknown[]): unknown {
        return this._storageService.getUser(userId)?.name;
    }

}
