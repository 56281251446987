import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {LocalizeService} from '@shared/helpers/localize.service';
import {MessageService} from 'abp-ng2-module';
import {ClientErrorServiceProxy, LogErrorDto} from '@shared/service-proxies/service-proxies';
import {catchError, first, of} from '@node_modules/rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private static stackTraceEntriesToIgnore = [
        'ConsumerObserver.next ',
        'Object.next ',
        'Observable.subscribe ',
        'SafeSubscriber._next ',
        'SafeSubscriber.next ',
        'Observable._subscribe ',
        'Observable._trySubscribe ',
        '.onInvokeTask ',
        '.invokeTask ',
        'executeListenerWithErrorHandling ',
    ];

    latestErrorMessage: string;

    constructor(private _message: MessageService,
                private _localize: LocalizeService,
                private _clientError: ClientErrorServiceProxy,
                private _zone: NgZone) {
    }

    handleError(error: any) {
        if (error.isApiException) {
            return;
        }

        if (error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >= 0) {
            return;
        }

        // Check if it's an error from an HTTP response
        if (!(error instanceof HttpErrorResponse) && error.rejection) {
            error = error.rejection; // get the error object
        }

        if (error.message) {
            if (this.latestErrorMessage === error.message) {
                return;
            }
            this.latestErrorMessage = error.message;
            setTimeout(() => this.latestErrorMessage = undefined, 30000);
        }

        this._zone
            .run(() => {
                let errorText = this._localize.l('UnexpectedErrorOccured');
                if (errorText === 'UnexpectedErrorOccured') {
                    errorText = 'Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später erneut.';
                }
                const el = document.getElementsByClassName('freeze-ui');
                if (el.length > 0) {
                    el[0].parentNode.removeChild(el[0]);
                }
                this._message.error(errorText);
            });

        const dto = new LogErrorDto();
        dto.message = error.message;
        try {
            if (error.stack?.indexOf('\n') > 0) {
                let splitted = error.stack.replace(/http(s)?:\/\/.+?\//g, '').split('\n');
                splitted = splitted.filter(entry => !GlobalErrorHandler
                    .stackTraceEntriesToIgnore
                    .some(ignore => entry.indexOf(ignore) > 0));
                dto.stackTrace = splitted.join('\n');
            }
        } catch {
        }

        if (!dto.stackTrace) {
            dto.stackTrace = error.stack;
        }

        this._clientError
            .logError(dto)
            .pipe(
                first(),
                catchError(ex => {
                    abp.log.error(ex);
                    return of();
                })
            )
            .subscribe();

        abp.log.error(error);
    }
}
