import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface AdvancedFilterParams {
    keyword: string;
    isActive: boolean | null;
    onlineBookingIsActive: boolean | null;
}

@Component({
    selector: 'app-advanced-filter',
    templateUrl: './advanced-filter.component.html'
})
export class AdvancedFilterComponent {
    @Output() onSearch = new EventEmitter<AdvancedFilterParams>();

    @Input() hasFilters: boolean;

    @Input() hasOnlineBookingFilters: boolean;

    params: AdvancedFilterParams = {
        keyword: undefined,
        isActive: true,
        onlineBookingIsActive: undefined,
    };

    advancedFiltersVisible = false;

    public search(): void {
        this.onSearch.emit(this.params);
    }

    public clearFilters(): void {
        this.params.keyword = '';
        this.params.isActive = true;
        this.params.onlineBookingIsActive = undefined;
        this.search();
    }
}
