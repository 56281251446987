import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Selection} from '@shared/selection';
import {BsDropdownContainerComponent} from '@node_modules/ngx-bootstrap/dropdown';
import {BsDropdownDirective} from '@node_modules/ngx-bootstrap/dropdown/bs-dropdown.directive';

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.less']
})
export class DropdownButtonComponent {
    @ViewChild('dropDown')
    dropDown: BsDropdownDirective;

    @Output()
    select = new EventEmitter<any>();

    public buttonText: string;

    @Input()
    checkbox: boolean;

    @Input()
    hideSelectAll = false;

    @Input()
    keepDefaultText = false;

    @Input()
    required = true;

    @Input()
    container = undefined;

    private _defaultText: string;
    private _selection: Selection<any>;
    private dropDownContainer: BsDropdownContainerComponent;

    constructor(private cdRef: ChangeDetectorRef) {
    }

    get defaultText(): string {
        return this._defaultText;
    }

    @Input()
    set defaultText(value: string) {
        this._defaultText = value;
        this.updateText();
    }

    get selection(): Selection<any> {
        return this._selection;
    }

    @Input()
    set selection(value: Selection<any>) {
        this._selection = value;
        if (!this._selection) {
            return;
        }
        this._selection?.onUpdate(() => {
            this.updateText();
        });
        this.updateText();
    }

    onSelect(value: string | number): void {
        this._selection.select([{value: value, selected: true}]);
    }

    onKeyDown(event: KeyboardEvent): void {
        const element = event.currentTarget as HTMLElement;
        if (!element) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        switch (event.key) {
            case 'Enter':
            case ' ':
                if (this.checkbox) {
                    const checkbox = element.querySelector('input[type=checkbox]') as HTMLInputElement;
                    checkbox.checked = !checkbox.checked;
                    const evt = new Event('change', {'bubbles': true, 'cancelable': true});
                    checkbox.dispatchEvent(evt);
                } else {
                    element.click();
                }
                this.cdRef.detectChanges();
                return;
            case 'ArrowUp':
                (element.previousElementSibling as HTMLElement)?.focus();
                return;
            case 'ArrowDown':
                (element.nextElementSibling as HTMLElement)?.focus();
                return;
            case 'Escape':
                this.dropDown.hide();
                return;
        }

        if (event.key.length !== 1 || !event.key.match(/[a-zA-Z0-9]/)) {
            return;
        }

        this.goto(element, event.key, false);
    }

    onShown(container: any): void {
        this.dropDownContainer = container as BsDropdownContainerComponent;
        this.selectFirstElement();
    }

    toggleAllSelected(): void {
        this.selection.selectAll(!this.selection.allSelected);
    }

    private updateText(): void {
        if (this.keepDefaultText) {
            this.buttonText = this._defaultText;
            return;
        }

        this.buttonText = this._selection?.items
            .filter(item => item.selected)
            .map(item => item.text)
            .join(', ');

        if (!this.buttonText && this._defaultText) {
            this.buttonText = this._defaultText;
        }
    }

    private goto(startElement: HTMLElement, text: string, equals: boolean): boolean {
        if (!startElement || !text) {
            return false;
        }

        let nextSibling = startElement.nextElementSibling;
        if (!nextSibling) {
            nextSibling = startElement.parentElement.firstElementChild;
        }
        const lowerText = text.toLowerCase();
        while (nextSibling !== startElement) {
            const liText = nextSibling.querySelector('span').textContent.toLowerCase();
            if (equals && lowerText === liText || !equals && liText.startsWith(lowerText)) {
                (nextSibling as HTMLElement)?.focus();
                return true;
            }

            nextSibling = nextSibling.nextElementSibling;
            if (!nextSibling) {
                nextSibling = startElement.parentElement.firstElementChild;
            }
        }

        return false;
    }

    private selectFirstElement(rounds = 0): void {
        let firstChild;
        if (this.container) {
            const element = (<any>this.dropDownContainer)?._element as ElementRef;
            firstChild = element?.nativeElement?.firstChild?.firstChild?.firstChild;
        } else {
            firstChild = (<any>this.dropDown)?._elementRef?.nativeElement?.querySelector('.dropdown-menu')?.firstChild;
        }

        const tryAgain = this.checkbox
            ? !firstChild?.nextElementSibling || typeof (firstChild?.nextElementSibling.focus) !== 'function'
            : !firstChild || typeof (firstChild.focus) !== 'function';
        if (tryAgain) {
            if (rounds < 20) {
                setTimeout(() => this.selectFirstElement(++rounds), 10);
            }
            return;
        }

        if (this.checkbox && this.hideSelectAll) {
            firstChild = firstChild.nextElementSibling;
        }

        if (this.buttonText && (!this.checkbox || this.selection.selectedItems?.length === 1)) {
            if (this.goto(firstChild, this.buttonText, true)) {
                return;
            }
        }

        firstChild.focus();
    }
}
