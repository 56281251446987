<div class="row">
    <div class="col-md-6">&emsp;</div>
    <div class="col-md-6">
        <div class="input-group">
            <div class="input-group-prepend">
                <button type="button"
                        class="btn btn-primary"
                        (click)="search()">
                    <i class="fas fa-search"></i>
                </button>
            </div>
            <input type="text"
                   class="form-control"
                   name="keyword"
                   [placeholder]="'SearchWithThreeDot' | localize"
                   [(ngModel)]="this.params.keyword"
                   (keyup.enter)="search()"/>
            <div class="input-group-append" *ngIf="hasFilters">
                <button type="button"
                        class="btn btn-default"
                        (click)="advancedFiltersVisible=!advancedFiltersVisible">
                    <i class="fas"
                       [class.fa-angle-up]="advancedFiltersVisible"
                       [class.fa-angle-down]="!advancedFiltersVisible"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="advancedFiltersVisible" class="card mb-0 mt-1">
    <div class="card-body">
        <form class="form-horizontal">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row mb-0">
                        <label class="col-form-label"
                               [ngClass]="{'col-md-3': !hasOnlineBookingFilters, 'col-md-5': hasOnlineBookingFilters}">
                            {{ "IsActive" | localize }}
                        </label>
                        <div class="pt-2"
                             [ngClass]="{'col-md-9': !hasOnlineBookingFilters, 'col-md-7': hasOnlineBookingFilters}">
                            <div class="custom-control custom-radio d-inline">
                                <input type="radio"
                                       class="custom-control-input"
                                       id="isActiveAll"
                                       name="isActive"
                                       [(ngModel)]="this.params.isActive"
                                       [value]="undefined"
                                       checked
                                />
                                <label class="custom-control-label" for="isActiveAll">
                                    {{ "All" | localize }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio d-inline mx-3">
                                <input type="radio"
                                       class="custom-control-input"
                                       id="isActiveActive"
                                       name="isActive"
                                       [(ngModel)]="this.params.isActive"
                                       [value]="true"
                                />
                                <label
                                        class="custom-control-label"
                                        for="isActiveActive">
                                    {{ "Yes" | localize }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio d-inline">
                                <input type="radio"
                                       class="custom-control-input"
                                       id="isActivePassive"
                                       name="isActive"
                                       [(ngModel)]="this.params.isActive"
                                       [value]="false"/>
                                <label class="custom-control-label"
                                       for="isActivePassive">
                                    {{ "No" | localize }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="hasOnlineBookingFilters">
                <div class="col-md-6">
                    <div class="form-group row mb-0">
                        <label class="col-md-5 col-form-label">
                            {{ "IsActiveForOnlineBooking" | localize }}
                        </label>
                        <div class="col-md-7 pt-2">
                            <div class="custom-control custom-radio d-inline">
                                <input type="radio"
                                       class="custom-control-input"
                                       id="onlineBookingIsActiveAll"
                                       name="onlineBookingIsActive"
                                       [(ngModel)]="this.params.onlineBookingIsActive"
                                       [value]="undefined"
                                       checked
                                />
                                <label class="custom-control-label" for="onlineBookingIsActiveAll">
                                    {{ "All" | localize }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio d-inline mx-3">
                                <input type="radio"
                                       class="custom-control-input"
                                       id="onlineBookingIsActiveActive"
                                       name="onlineBookingIsActive"
                                       [(ngModel)]="this.params.onlineBookingIsActive"
                                       [value]="true"
                                />
                                <label
                                        class="custom-control-label"
                                        for="onlineBookingIsActiveActive">
                                    {{ "Yes" | localize }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio d-inline">
                                <input type="radio"
                                       class="custom-control-input"
                                       id="onlineBookingIsActivePassive"
                                       name="onlineBookingIsActive"
                                       [(ngModel)]="this.params.onlineBookingIsActive"
                                       [value]="false"/>
                                <label class="custom-control-label"
                                       for="onlineBookingIsActivePassive">
                                    {{ "No" | localize }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="card-footer">
        <button type="button"
                class="btn btn-primary"
                (click)="search()">
            {{ "Search" | localize }}
        </button>
        <button type="button"
                class="btn btn-default float-right"
                (click)="clearFilters()">
            {{ "Clear" | localize }}
        </button>
    </div>
</div>
