import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {catchError, map, Observable, Observer, of, switchMap} from '@node_modules/rxjs';
import {PatientDto, PatientServiceProxy} from '@shared/service-proxies/service-proxies';
import {TypeaheadMatch} from '@node_modules/ngx-bootstrap/typeahead';
import {TextBuilderService} from '@shared/text-builder.service';
import {LocalizeService} from '@shared/helpers/localize.service';

@Component({
    selector: 'app-search-patient',
    templateUrl: './search-patient.component.html',
    styleUrls: ['./search-patient.component.less']
})
export class SearchPatientComponent implements OnInit {
    @ViewChild('patientSearchEl')
    patientSearchEl;

    @Output()
    patientSelected = new EventEmitter<PatientDto>();

    @Input()
    required: boolean;

    @Input()
    keepClosed: boolean;

    @Input()
    showClear: boolean;

    @Input()
    showCopyPatient = true;

    @Input()
    autoFocus = true;

    @Input()
    searchPatientWithExternalId = undefined;

    @Input()
    container?: string;

    isPatientLoading = false;

    patients$?: Observable<PatientDto[]>;
    searchPatient?: string;
    selectedPatientText: string;
    showPatientSearch = false;
    searchPatientErrorMessage: string = undefined;

    private _patient: PatientDto;

    constructor(private _textBuilderService: TextBuilderService,
                private _patientService: PatientServiceProxy,
                private _localizeService: LocalizeService) {
    }

    @Input()
    set patient(value: PatientDto) {
        this._patient = value;
        if (!this._patient) {
            this.searchPatient = undefined;
        }
        this.selectedPatientText = this._textBuilderService.patientText(this._patient);
        if (this.keepClosed) {
            return;
        }
        this.showPatientSearch = !this.selectedPatientText;
        if (this.showPatientSearch && this.autoFocus) {
            setTimeout(() => this.patientSearchEl?.nativeElement?.focus(), 100);
        }
    }

    get patient(): PatientDto {
        return this._patient;
    }

    public ngOnInit(): void {
        this.patients$ = new Observable((observer: Observer<string | undefined>) => {
            this.searchPatientErrorMessage = undefined;
            observer.next(this.searchPatient);
        }).pipe(
            catchError(() => {
                this.searchPatientErrorMessage = this._localizeService.l('ErrorWhileSearchingPatients');
                return of([]);
            }),
            switchMap((keyword: string) => {
                if (keyword) {
                    return this._patientService
                        .getList(keyword, undefined, undefined, this.searchPatientWithExternalId, undefined, 200)
                        .pipe(
                            map((data) => data && data.items || []),
                        );
                }
                return of([]);
            })
        );
    }

    public togglePatientSearch(): void {
        this.showPatientSearch = !this.showPatientSearch;
        this.searchPatient = undefined;
        if (this.showPatientSearch) {
            setTimeout(() => this.patientSearchEl.nativeElement.focus());
        }
    }

    public changePatientLoading(e: boolean): void {
        this.isPatientLoading = e;
    }

    public patientOnSelect($event: TypeaheadMatch) {
        this.patientSelected.emit($event.item);
        this.showPatientSearch = false;
    }

    public buildPatientText(patient: PatientDto): string {
        return this._textBuilderService.patientText(patient);
    }

    public clearPatient(): void {
        this.patient = undefined;
        this.patientSelected.emit(undefined);
    }
}
