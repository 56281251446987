import {Injectable} from '@angular/core';
import {environment} from '../src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, first, map, of} from '@node_modules/rxjs';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    currentVersion = environment.version;

    constructor(private _http: HttpClient) {
    }

    checkUpdate(): void {
        this
            ._http
            .get(`/environments/version.json?=${new Date().getTime()}`)
            .pipe(
                first(),
                catchError(() => {
                    return of({version: this.currentVersion});
                })
            )
            .subscribe((data: {version: string}) => {
                if(data.version !== this.currentVersion) {
                    document.location.replace('/?nc=' + new Date().getTime());
                }
            })
    }
}
