import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-card-collapse',
  templateUrl: './card-collapse.component.html',
  styleUrls: ['./card-collapse.component.less']
})
export class CardCollapseComponent {

    public collapse: boolean;

    @Input()
    title: string;

    @Input()
    animated: boolean;

    @Input()
    set collapsed(value: boolean) {
        this.collapse = value;
    }
}
