<div class="input-group">
    <div class="input-group-prepend" *ngIf="showCopyPatient">
        <app-copy-patient [patient]="patient"></app-copy-patient>
    </div>
    <input [ngModel]="selectedPatientText"
           required
           readonly="readonly"
           class="form-control"
           id="patientText"
           name="patientText"
           #patientIdModel="ngModel"
           #patientIdEl>
    <div class="input-group-append btn-group">
        <button type="button" class="btn btn-primary" (click)="togglePatientSearch()">
            <i class="fas"
               [ngClass]="{'fa-caret-up': showPatientSearch, 'fa-caret-down': !showPatientSearch}"></i>
        </button>
        <button type="button" class="btn btn-default" *ngIf="showClear" (click)="clearPatient()">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <abp-validation-summary *ngIf="required"
                            [ngClass]="'validation-in-input-group'"
                            [control]="patientIdModel"
                            [controlEl]="patientIdEl"
    ></abp-validation-summary>
</div>
<div [hidden]="!showPatientSearch" class="mt-2">
    <div class="input-group">
        <div class="input-group-append">
            <div class="input-group-text">
                <i class="fas fa-search" [ngClass]="{'fa-spin': isPatientLoading}"></i>
            </div>
        </div>
        <input [(ngModel)]="searchPatient"
               typeaheadOptionField="id"
               [typeahead]="patients$"
               [typeaheadAsync]="true"
               [typeaheadItemTemplate]="patientTemplate"
               (typeaheadOnSelect)="patientOnSelect($event)"
               (typeaheadLoading)="changePatientLoading($event)"
               [typeaheadWaitMs]="300"
               [typeaheadMinLength]="3"
               [isAnimated]="true"
               [placeholder]="'ChoosePatient' | localize"
               [typeaheadOptionsLimit]="40"
               autocomplete="off"
               class="form-control"
               id="patientId"
               name="patientId"
               [container]="container"
               #patientSearchEl>
        <ng-template #patientTemplate let-patient="item">
            {{buildPatientText(patient)}}
        </ng-template>
        <div class="alert alert-danger validation-in-input-group" role="alert"
             *ngIf="searchPatientErrorMessage">
            {{searchPatientErrorMessage}}
        </div>
    </div>
</div>
