<div class="input-group">
    <div class="input-group-prepend">
        <div class="input-group-text">
            <span class="fas fa-calendar-days"></span>
        </div>
    </div>
    <div class="input-group-append">
        <div class="form-check"
             *ngFor="let item of weekDaySelection.items">
            <label class="form-check-label" [title]="item.text">
                <input class="form-check-input" type="checkbox"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="item.selected"
                       (change)="weekDaySelection.update()">
                <span>{{item.text}}</span>
            </label>
        </div>
    </div>
</div>
