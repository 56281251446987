<div class="btn-group btn-group-sm" dropdown [container]="container"
     (onShown)="onShown($event)" [insideClick]="checkbox" #dropDown="bs-dropdown">
    <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
            [ngClass]="{'danger': required && !selection?.selectedItems?.length}">
        <span class="overflow-text-inside-button">{{buttonText}}</span>
    </button>
    <ng-container *ngIf="checkbox else noCheckbox">
        <ul *dropdownMenu class="dropdown-menu padding-no-wrap" role="menu">
            <li role="menuitem" class="select-all" *ngIf="!hideSelectAll"
                (keydown)="onKeyDown($event)" [tabIndex]="0">
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox"
                               [ngModel]="selection.allSelected" (change)="toggleAllSelected()">
                        <span>{{'All' | localize}}</span>
                    </label>
                </div>
            </li>
            <li role="menuitem" *ngFor="let item of selection?.items"
                (keydown)="onKeyDown($event)" [tabIndex]="0">
                <div class="form-check">
                    <label class="form-check-label" [title]="item.text">
                        <input class="form-check-input" type="checkbox"
                               [(ngModel)]="item.selected" (change)="selection.update()">
                        <span>{{item.text}}</span>
                    </label>
                </div>
            </li>
        </ul>
    </ng-container>
    <ng-template #noCheckbox>
        <ul *dropdownMenu class="dropdown-menu padding-no-wrap" role="menu">
            <li role="menuitem" *ngFor="let item of selection?.items"
                (keydown)="onKeyDown($event)" [tabIndex]="0"
                [ngClass]="{'dropdown-item-selected': item.selected}"
                (click)="onSelect(item.value)">
                <span class="dropdown-item">{{item.text}}</span>
            </li>
        </ul>
    </ng-template>
</div>
