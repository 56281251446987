import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.ClientErrorServiceProxy,
        ApiServiceProxies.AppointmentServiceProxy,
        ApiServiceProxies.PatientServiceProxy,
        ApiServiceProxies.PractitionerServiceProxy,
        ApiServiceProxies.CommunicationServiceProxy,
        ApiServiceProxies.AppointmentTypeServiceProxy,
        ApiServiceProxies.AppointmentTemplateServiceProxy,
        ApiServiceProxies.OfficeHourServiceProxy,
        ApiServiceProxies.DmpAppointmentServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
