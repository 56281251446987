import {CommonModule} from '@angular/common';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';

import {AppSessionService} from './session/app-session.service';
import {AppUrlService} from './nav/app-url.service';
import {AppAuthService} from './auth/app-auth.service';
import {AppRouteGuard} from './auth/auth-route-guard';
import {LocalizePipe} from '@shared/pipes/localize.pipe';

import {AbpPaginationControlsComponent} from './components/pagination/abp-pagination-controls.component';
import {AbpValidationSummaryComponent} from './components/validation/abp-validation.summary.component';
import {AbpModalHeaderComponent} from './components/modal/abp-modal-header.component';
import {AbpModalFooterComponent} from './components/modal/abp-modal-footer.component';
import {LayoutStoreService} from './layout/layout-store.service';

import {BusyDirective} from './directives/busy.directive';
import {EqualValidator} from './directives/equal-validator.directive';
import {MomentDatePipe} from './pipes/moment-date.pipe';
import {MomentDateTimePipe} from './pipes/moment-date-time.pipe';
import {StorageService} from '@shared/storage.service';
import {UsernamePipe} from './pipes/username.pipe';
import {EditTerminGliedComponent} from '@shared/components/edit-termin-glied/edit-termin-glied.component';
import {FormsModule} from '@angular/forms';
import {BsDropdownModule} from '@node_modules/ngx-bootstrap/dropdown';
import {AdvancedFilterComponent} from './components/advanced-filter/advanced-filter.component';
import {CreatedByPipe} from './pipes/created-by.pipe';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {DropdownButtonComponent} from './dropdown-button/dropdown-button.component';
import {WeekdaySelectComponent} from './components/weekday-select/weekday-select.component';
import {CopyPatientComponent} from './copy-patient/copy-patient.component';
import {SearchPatientComponent} from './components/search-patient/search-patient.component';
import {TypeaheadModule} from '@node_modules/ngx-bootstrap/typeahead';
import {TextBuilderService} from '@shared/text-builder.service';
import {CardCollapseComponent} from './components/card-collapse/card-collapse.component';
import {CollapseModule} from '@node_modules/ngx-bootstrap/collapse';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        TypeaheadModule.forRoot(),
        CollapseModule.forRoot(),
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        MomentDatePipe,
        MomentDateTimePipe,
        UsernamePipe,
        EditTerminGliedComponent,
        AdvancedFilterComponent,
        CreatedByPipe,
        LoadingSpinnerComponent,
        DropdownButtonComponent,
        WeekdaySelectComponent,
        CopyPatientComponent,
        SearchPatientComponent,
        CardCollapseComponent
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        MomentDatePipe,
        MomentDateTimePipe,
        UsernamePipe,
        EditTerminGliedComponent,
        AdvancedFilterComponent,
        CreatedByPipe,
        LoadingSpinnerComponent,
        DropdownButtonComponent,
        WeekdaySelectComponent,
        CopyPatientComponent,
        SearchPatientComponent,
        CardCollapseComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                StorageService,
                TextBuilderService
            ]
        };
    }
}
