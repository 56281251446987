import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
    transform(value: Date | moment.Moment, dateFormat?: string): string {
        if (!value) {
            return '';
        }
        if (!dateFormat) {
            dateFormat = 'DD.MM.yyyy';
        }

        // Bei reinen Daten Werten (ohne Uhrzeit) wird die Zone ignoriert.
        // Denn ab 1940 gibt es die Sommerzeit. Das führt bei älteren Daten zu Problemen:
        // moment('1939-05-22T00:00:00+02:00').format('DD.MM.yyyy HH:mm') = '21.05.1939 23:00'
        // moment('1940-05-22T00:00:00+02:00').format('DD.MM.yyyy HH:mm') = '22.05.1940 00:00'
        return dateFormat.indexOf('HH:mm') < 0
            ? moment.parseZone(value).format(dateFormat)
            : moment(value).format(dateFormat);
    }
}
