import { Pipe, PipeTransform } from '@angular/core';
import {StorageService} from '@shared/storage.service';
import * as moment from 'moment';

@Pipe({
  name: 'createdBy'
})
export class CreatedByPipe implements PipeTransform {

    constructor(private _storageService: StorageService) {
    }

    transform(userId: number, timestamp: moment.Moment, ...args: unknown[]): string {
        if (!userId && !timestamp) {
            return '';
        }

        const creatorUserId = userId
            ? this._storageService.getUser(userId)?.name
            : 'Import';
        return `${creatorUserId} (${moment(timestamp).format('DD.MM.YY')})`;
    }
}
