import {Component, Input} from '@angular/core';
import {PatientDto} from '@shared/service-proxies/service-proxies';
import {NotifyService} from 'abp-ng2-module';
import {LocalizeService} from '@shared/helpers/localize.service';

@Component({
    selector: 'app-copy-patient',
    templateUrl: './copy-patient.component.html'
})
export class CopyPatientComponent {

    @Input()
    small = false;

    public name: string;

    constructor(private _notifyService: NotifyService,
                private _localizeService: LocalizeService) {
    }

    @Input()
    public set patient(value: PatientDto) {
        this.name = value?.firstName && value.lastName
            ? `${value.lastName}, ${value.firstName}`
            : undefined;
    }

    public copyPatient(): void {
        this.copyToClipboard(this.name);
    }

    private copyToClipboard(text: string): void {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                this._notifyService.info(this._localizeService.l('CopiedToClipboard'));
            }).catch(err => {
                this._notifyService.error(this._localizeService.l('CopiedToClipboardFailed'));
            });
        } else {
            this.fallbackCopyTextToClipboard(text);
        }
    }

    private fallbackCopyTextToClipboard(text: string): void {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            this._notifyService.info(this._localizeService.l('CopiedToClipboard'));
        } catch (err) {
            this._notifyService.error(this._localizeService.l('CopiedToClipboardFailed'));
        }

        document.body.removeChild(textArea);
    }
}
