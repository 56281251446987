import {Injectable} from '@angular/core';
import {PatientListDto} from './service-proxies/service-proxies';
import * as moment from 'moment/moment';

@Injectable({
    providedIn: 'root'
})
export class TextBuilderService {

    constructor() {
    }

    patientText(patient: PatientListDto): string {
        if (!patient?.lastName && !patient?.firstName) {
            return '';
        }

        const text = `${patient.lastName}, ${patient.firstName}`;
        if (patient.dateOfBirth) {
            return text + ` (${moment.parseZone(patient.dateOfBirth).format('DD.MM.yyyy')})`;
        }

        return text;
    }
}
