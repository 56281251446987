import {Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Selection, SelectItem} from '@shared/selection';
import {AppointmentTypeDto, PractitionerDto, TerminGliedDto} from '@shared/service-proxies/service-proxies';
import {StorageService} from '@shared/storage.service';
import {AppComponentBase} from '@shared/app-component-base';
import {first} from '@node_modules/rxjs';

@Component({
    selector: 'app-edit-termin-glied',
    templateUrl: './edit-termin-glied.component.html',
    styleUrls: ['./edit-termin-glied.component.less']
})
export class EditTerminGliedComponent extends AppComponentBase implements OnInit {
    @ViewChild('terminGliedTemplate') terminGliedTemplate: TemplateRef<any>;

    practitionerSelection: Selection<PractitionerDto>;
    appointmentTypeSelection: Selection<AppointmentTypeDto>;
    appointmentButtonText: string;
    practitionerButtonText: string;

    @Input() container = '.modal-content';

    @Input() terminglied: TerminGliedDto;

    @Input() showBreakTime = false;

    @Output() onUpdate = new EventEmitter<any>();

    @Output() onDelete = new EventEmitter<any>();

    pufferVorgabenSelection: Selection<SelectItem>;

    constructor(injector: Injector,
                private _storageService: StorageService) {
        super(injector);
    }

    ngOnInit(): void {
        this._storageService
            .init()
            .pipe(
                first(),
            )
            .subscribe(() => {
                this.practitionerSelection = new Selection<PractitionerDto>(this._storageService
                    .practitioners.filter(p => p.isActive), 'id', 'name');
                this.appointmentTypeSelection = new Selection<AppointmentTypeDto>(this._storageService
                    .appointmentTypes.filter(t => t.isActive), 'id', 'name');

                if (this.terminglied.practitionerIds) {
                    const selection = this.terminglied.practitionerIds
                        .map(id => ({value: id, selected: true}));
                    this.practitionerSelection.select(selection);
                }

                if (this.terminglied.typeIds) {
                    const selection = this.terminglied.typeIds
                        .map(id => ({value: id, selected: true}));
                    this.appointmentTypeSelection.select(selection);
                }

                const pufferVorgaben = [
                    {value: 0, text: this.l('Minutes')},
                    {value: 1, text: '1 ' + this.l('Day')},
                    {value: 2, text: '2 ' + this.l('Days')},
                    {value: 3, text: '3 ' + this.l('Days')},
                    {value: 4, text: '4 ' + this.l('Days')}
                ];
                let days = Math.round(this.terminglied.breakDurationInMin / 1440);
                if (isNaN(days)) {
                    days = 0;
                }
                if (!pufferVorgaben.find(pv => pv.value === days)) {
                    for (let day = 1; day <= days; day++) {
                        if (!pufferVorgaben.find(pv => pv.value === day)) {
                            pufferVorgaben.push({value: day, text: `${day} ` + this.l('Days')});
                        }
                    }
                }
                this.pufferVorgabenSelection = new Selection<SelectItem>(pufferVorgaben);
                this.pufferVorgabenSelection.select([{value: days, selected: true}]);
                this.pufferVorgabenSelection.onUpdate(() => {
                    if (!this.pufferVorgabenSelection.selectedItems.length) {
                        return;
                    }

                    const value = <number>this.pufferVorgabenSelection.selectedItems[0].value;
                    if (value > 0) {
                        this.terminglied.breakDurationInMin = value * 1440;
                    } else if (this.terminglied.breakDurationInMin > 1000) {
                        this.terminglied.breakDurationInMin = 10;
                    }
                    this.onUpdate.emit(undefined);
                });

                this.appointmentTypeSelection.onUpdate(() => {
                    this.terminglied.typeIds = this.appointmentTypeSelection.selectedIds;
                    this.terminglied.durationInMin = 0;
                    this.appointmentTypeSelection.selectedItems.forEach(item => {
                        this.terminglied.durationInMin = this.terminglied.durationInMin = +item.durationInMin;
                    });
                    this.updateAppointmentTypesButtonText();
                    this.onUpdate.emit(undefined);
                });

                this.practitionerSelection.onUpdate(() => {
                    this.terminglied.practitionerIds = this.practitionerSelection.selectedIds;
                    this.updatePractitionerButtonText();
                    this.onUpdate.emit(undefined);
                });

                this.updateAppointmentTypesButtonText();
                this.updatePractitionerButtonText();
            });
    }

    updateAppointmentTypesButtonText(): void {
        if (!this.appointmentTypeSelection.selectedItems.length) {
            this.appointmentButtonText = this.l('AppointmentTypes');
            return;
        }

        const names = this.appointmentTypeSelection.selectedItems.map(item => item.name);
        this.appointmentButtonText = names.join(', ');
    }

    updatePractitionerButtonText(): void {
        if (!this.practitionerSelection.selectedItems.length) {
            this.practitionerButtonText = this.l('Practitioners');
            return;
        }

        const names = this.practitionerSelection.selectedItems.map(item => item.name);
        this.practitionerButtonText = names.join(', ');
    }
}
