<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default"
            [disabled]="cancelDisabled" (click)="onCancelClick.emit()">
        {{ cancelLabel }}
    </button>
    <button type="button" class="btn btn-primary"
            [disabled]="saveDisabled" (click)="onSaveClick.emit()">
        {{ saveLabel }}
    </button>
</div>
