import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'momentDateTime'
})
export class MomentDateTimePipe implements PipeTransform {
    transform(value: Date | moment.Moment, dateFormat?: string): string {
        if (!value) {
            return '';
        }
        if (!dateFormat) {
            dateFormat = 'DD.MM.yyyy HH:mm';
        }
        return moment(value).format(dateFormat);
    }
}
