<ng-container *ngIf="small else large">
    <a href="javascript:;" (click)="copyPatient()">
        <i class="fa-regular fa-copy"></i>
    </a>
</ng-container>
<ng-template #large>
    <button type="button" *ngIf="name"
            class="btn btn-secondary"
            (click)="copyPatient()">
        <i class="fa-regular fa-copy"></i>
    </button>
</ng-template>
