export interface SelectItem {
    value: number | string;
    text: string;
}

export class Selection<TModel> {
    items: { selected: boolean, value: number, text: string, data: TModel }[] = [];
    selectedValue: number | string;
    selectedItems: TModel[] = [];
    allSelected = false;
    sortBySelect = true;
    private readonly _valueProperty: string;
    private readonly _callbacks: (() => void)[] = [];

    constructor(models: TModel[], valuePropery = 'value', textProperty = 'text') {
        this._valueProperty = valuePropery;
        this.items = models
            .map(model => {
                return {
                    selected: false,
                    value: model[this._valueProperty],
                    text: model[textProperty],
                    data: model
                };
            });
    }

    public get selectedIds(): number[] {
        return this.items
            .filter(item => item.selected)
            .map(item => item.value);
    }

    public set selectedIds(ids: number[]) {
        this.items
            .forEach(item => item.selected = ids.indexOf(item.value) >= 0);
    }

    selectAll(value: boolean): void {
        if (this.allSelected === value) {
            return;
        }
        this.items.forEach(item => item.selected = value);
        this.allSelected = value;
        this.update();
    }

    get(id: number): TModel {
        const item = this
            .items
            .find(i => i.value === id);
        if (item) {
            return item.data;
        }
        return undefined;
    }

    update(): void {
        this.selectedItems = this.items
            .filter(item => item.selected)
            .map(item => item.data);

        this.allSelected = this.selectedItems.length === this.items.length;

        this.selectedValue = this.items.find(item => item.selected)?.value;

        this._callbacks.forEach(callback => callback());
    }

    onUpdate(callback: () => void) {
        this._callbacks.push(callback);
    }

    select(selection: { value: number | string; selected: boolean }[]) {
        if (this.sortBySelect && selection?.length > 0) {
            const selectionMap = new Map(selection.map(item => [item.value, item.selected]));

            this.items = this.items.map(item => ({
                ...item,
                selected: selectionMap.has(item.value) ? selectionMap.get(item.value) : false
            }));
        } else {
            const selectionMap = selection?.reduce((map, item) => map.set(item.value, item.selected), new Map());

            this.items = this.items.map(item => ({
                ...item,
                selected: selectionMap?.get(item.value) || false
            }));
        }

        this.update();
    }

    getSelection(): { value: number; selected: boolean }[] {
        return this.items.map(item => {
            return {
                value: item.value,
                selected: item.selected
            };
        });
    }
}
