import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Selection, SelectItem} from '@shared/selection';
import {LocalizeService} from '@shared/helpers/localize.service';

@Component({
    selector: 'app-weekday-select',
    templateUrl: './weekday-select.component.html',
    styleUrls: ['./weekday-select.component.less']
})
export class WeekdaySelectComponent implements OnInit {
    @Output()
    changed = new EventEmitter<number[]>();

    @Input()
    selectedWeekDays: number[];

    weekDaySelection: Selection<SelectItem>;

    constructor(private _localizeService: LocalizeService) {
    }

    ngOnInit(): void {
        const weekdayNrs = [
            {value: 0, text: this._localizeService.l('Weekday_Short_MO')},
            {value: 1, text: this._localizeService.l('Weekday_Short_TU')},
            {value: 2, text: this._localizeService.l('Weekday_Short_WE')},
            {value: 3, text: this._localizeService.l('Weekday_Short_TH')},
            {value: 4, text: this._localizeService.l('Weekday_Short_FR')},
        ];
        this.weekDaySelection = new Selection<SelectItem>(weekdayNrs);
        this.weekDaySelection.select(this.selectedWeekDays.map(s => ({value: s, selected: true})));
        this.weekDaySelection.onUpdate(() => {
            const selectedDays = this.weekDaySelection.selectedItems.map(item => <number>item.value);
            this.selectedWeekDays.length = 0;
            this.selectedWeekDays.push(...selectedDays);
            this.changed.emit();
        });
    }
}
